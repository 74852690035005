<template>
  <div>
    <div
      class="text-center"
      v-html="$t('rating.textarea.question')"
    >
    </div>
    <br>
    <v-textarea
      outlined
      clearable
      clear-icon="mdi-close-circle"
      :label="$t('rating.textarea.label')"
      :rows="$vuetify.breakpoint.xs ? 3 : 5"
      v-model="text"
    ></v-textarea>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TextRating',
  computed: {
    ...mapFields('rating', [
      'text',
    ]),
  },
};
</script>
